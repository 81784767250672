<template>
  <div>
    <!-- SINGLE PROPERTY-->

    <div
      :style="{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${$api}/uploads/${honeymoonPage.banner})`,
      }"
      style="background-size: cover; background-position: center"
      class="w-full mx-auto px-4 p-20 h-96"
    >
      <div class="max-w-6xl text-center mx-auto container text-white mt-20">
        <h1 class="text-4xl text-center uppercase pb-2">
          {{ honeymoonPage.title }}
        </h1>
        <div class="border-white border w-12 text-center mx-auto my-2"></div>

        <span class="text-xs text-center"> {{ honeymoonPage.title }}</span>
      </div>
    </div>

    <div
      class="
        text-section
        max-w-3xl
        md:px-0
        px-10
        mx-auto
        container
        leading-7
        text-center
        bg-white
        text-sm text-black
        m-16
      "
      v-html="honeymoonPage.firstTextSection"
    ></div>

    <!-- slide -->

    <splide
      class="
        max-w-6xl
        mx-auto
        container
        leading-7
        align-middle
        text-center
        bg-white
        text-sm text-black
        m-12
      "
      :slides="sliderSlides"
      :options="mainSliderOptions"
    >
      <splide-slide
        v-for="(slide, i) in sliderSlides"
        :key="i"
        :style="{
          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${$api}/uploads/${slide.image})`,
        }"
        style="background-size: cover; background-position: center"
        class="splide__slide"
      >
        <h1 class="text-xl text-white uppercase pt-20">
          the little grey book of honeymoons
        </h1>
        <button
          style="background-color: #948d79"
          class="uppercase text-white px-6 py-4 mt-7"
        >
          request brochure
        </button>
      </splide-slide>
    </splide>

    <!-- slide // -->

    <div class="max-w-6xl mx-auto">
      <section
        class="text-section px-10 text-gray-800 text-center"
        v-html="honeymoonPage.secondTextSection"
      ></section>
      <section class="py-4 mb-28 m-4 md:m-0">
        <LinksGrid :properties="honeymoonProperties"> </LinksGrid>
      </section>
    </div>
  </div>
</template>

<script>
import LinksGrid from "@/components/layout/LinksGrid";

export default {
  name: "Honeymoon",
  data() {
    return {
      honeymoonPage: {},
      mainSliderOptions: {
        clones: 3,
        arrows: false,
        autoplay: true,
        type: "loop",
        height: 300,
        interval: 4000,
      },
      honeymoonProperties: [],
    };
  },
  methods: {
    async getHoneymoonPage() {
      try {
        const res = await this.$axios.get(`${this.$api_v1}/honeymoonPage`);

        if (res.status === 200) {
          this.honeymoonPage = res.data.page;
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async getHoneymoonProperties() {
      try {
        const res = await this.$axios(`${this.$api_v1}/honeymoonProperties`);

        if (res.status === 200) {
          this.honeymoonProperties = res.data.honeymoonProperties.map(
            (p) => p.property
          );
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
  computed: {
    sliderSlides() {
      if (!this.honeymoonProperties || !this.honeymoonProperties.length)
        return [];

      let arr = this.honeymoonProperties.map((p) => {
        if (p.honeymoonSliderImage && p.honeymoonSliderImage.image) {
          return p.honeymoonSliderImage;
        } else {
          return null;
        }
      });

      arr = arr.filter((item) => item);

      console.log(arr);
      return arr;
    },
  },
  mounted() {
    this.getHoneymoonPage();
    this.getHoneymoonProperties();
  },
  components: {
    LinksGrid,
  },
};
</script>

<style scoped>
.villa-card {
  &:nth-child(even) {
    .villa-card--image-container {
      order: -1;
    }
  }
}
</style>  

 